ion-button.button-default,
ion-button.button-solid {
  --border-radius: 27px !important;
}

.in-toolbar.in-segment-color {
  color: #fff !important;
  &.ios.segment-button-checked {
    color: black !important;
  }
}
