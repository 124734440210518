// https://ionicframework.com/docs/theming/color-generator

/** Elywhere color variables **/
:root {
  --ion-color-primary: #00bcf2;
  --ion-color-primary-rgb: 0, 188, 242;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #00a5d5;
  --ion-color-primary-tint: #1ac3f3;

  --ion-color-primary-dark: #00bcf2;
  --ion-color-primary-dark-rgb: 0, 188, 242;
  --ion-color-primary-dark-contrast: #ffffff;
  --ion-color-primary-dark-contrast-rgb: 255, 255, 255;
  --ion-color-primary-dark-shade: #00a5d5;
  --ion-color-primary-dark-tint: #1ac3f3;

  --ion-color-secondary: #00dca0;
  --ion-color-secondary-rgb: 0, 220, 160;
  --ion-color-secondary-contrast: #FFFFFF;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #00c28d;
  --ion-color-secondary-tint: #1ae0aa;

  --ion-color-success: #00dca0;
  --ion-color-success-rgb: 0, 220, 160;
  --ion-color-success-contrast: #FFFFFF;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #00c28d;
  --ion-color-success-tint: #1ae0aa;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #001f38;
  --ion-color-dark-rgb: 0, 31, 56;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #001b31;
  --ion-color-dark-tint: #1a354c;

  --ion-color-gray: #989aa2;
  --ion-color-gray-rgb: 152, 154, 162;
  --ion-color-gray-contrast: #000000;
  --ion-color-gray-contrast-rgb: 0, 0, 0;
  --ion-color-gray-shade: #86888f;
  --ion-color-gray-tint: #a2a4ab;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-black: #000000;
  --ion-color-black-rgb: 0, 0, 0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #1a1a1a;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #dadada;
  --ion-color-white-tint: #f9f9f9;

  --ion-color-charging: #039de2;
  --ion-color-charging-rgb: 3, 157, 226;
  --ion-color-charging-contrast: #ffffff;
  --ion-color-charging-contrast-rgb: 255, 255, 255;
  --ion-color-charging-shade: #038ac7;
  --ion-color-charging-tint: #1ca7e5;

  --ion-color-toolbar: #001f38;
  --ion-color-toolbar-rgb: 255, 255, 255;
  --ion-color-toolbar-contrast: #ffffff;
  --ion-color-toolbar-contrast-rgb: 119, 184, 38;
  --ion-color-toolbar-shade: #dadada;
  --ion-color-toolbar-tint: #f9f9f9;

  --ion-color-toolbar-button: #00bcf2;
  --ion-color-toolbar-button-rgb: 0, 188, 242;
  --ion-color-toolbar-button-contrast: #ffffff;
  --ion-color-toolbar-button-contrast-rgb: 255, 255, 255;
  --ion-color-toolbar-button-shade: #038ac7;
  --ion-color-toolbar-button-tint: #1ca7e5;
}
.ion-color-primary {
  --ion-color-base: var(--ion-color-primary);
  --ion-color-base-rgb: var(--ion-color-primary-rgb);
  --ion-color-contrast: var(--ion-color-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-shade);
  --ion-color-tint: var(--ion-color-primary-tint);
}
.ion-color-primary-dark{
  --ion-color-base: var(--ion-color-primary-dark);
  --ion-color-base-rgb: var(--ion-color-primary-dark-rgb);
  --ion-color-contrast: var(--ion-color-primary-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-dark-shade);
  --ion-color-tint: var(--ion-color-primary-dark-tint);
}
.ion-color-gray {
  --ion-color-base: var(--ion-color-gray);
  --ion-color-base-rgb: var(--ion-color-gray-rgb);
  --ion-color-contrast: var(--ion-color-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-gray-shade);
  --ion-color-tint: var(--ion-color-gray-tint);
}
.ion-color-toolbar {
  --ion-color-base: var(--ion-color-toolbar);
  --ion-color-base-rgb: var(--ion-color-toolbar-rgb);
  --ion-color-contrast: var(--ion-color-toolbar-contrast);
  --ion-color-contrast-rgb: var(--ion-color-toolbar-contrast-rgb);
  --ion-color-shade: var(--ion-color-toolbar-shade);
  --ion-color-tint: var(--ion-color-toolbar-tint);
}
.ion-color-toolbar-button {
  --ion-color-base: var(--ion-color-toolbar-button);
  --ion-color-base-rgb: var(--ion-color-toolbar-button-rgb);
  --ion-color-contrast: var(--ion-color-toolbar-button-contrast);
  --ion-color-contrast-rgb: var(--ion-color-toolbar-button-contrast-rgb);
  --ion-color-shade: var(--ion-color-toolbar-button-shade);
  --ion-color-tint: var(--ion-color-toolbar-button-tint);
}
.ion-color-toolbar-contrast {
  --ion-color-base: var(--ion-color-toolbar-contrast);
}
.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}
.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}
.ion-color-success {
  --ion-color-base: var(--ion-color-success);
  --ion-color-base-rgb: var(--ion-color-success-rgb);
  --ion-color-contrast: var(--ion-color-success-contrast);
  --ion-color-contrast-rgb: var(--ion-color-success-contrast-rgb);
  --ion-color-shade: var(--ion-color-success-shade);
  --ion-color-tint: var(--ion-color-success-tint);
}
